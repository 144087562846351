import { createSelector } from 'reselect';
import getOr from 'lodash/fp/getOr';
import {
    ENTITY_DELIVERABLE,
    ENTITY_DELIVERABLE_ID,
    ENTITY_DELIVERABLE_IDS_SCOPE_DEVICES,
    ENTITY_DELIVERABLE_SCOPE_DEVICES,
    ENTITY_DELIVERABLES_USER_INFO,
    ENTITY_DISTRO,
    ENTITY_LIVE_APPS,
    ENTITY_LIVE_BUNDLES,
    ENTITY_LIVE_CM4GS,
    ENTITY_LIVE_DISTROS,
} from '~/features/base/constants/entities';
import {
    aggregationStoreSelector,
    entityStoreSelector,
    indicesStoreSelector,
    loadingStoreSelector,
} from '~/features/higherorder/selectors/dataSelectors';
import get from 'lodash/fp/get';
import {pathnameSelector, searchSelector} from '~/features/base/selectors/locationSelectors';

import {
    DELIVERABLES_APPS_PATH,
    DELIVERABLES_BUNDLES_PATH,
    DELIVERABLES_CM4G_PATH,
    DELIVERABLES_DISTROS_PATH,
    DELIVERABLES_FILES_PATH,
    DELIVERABLES_PATH,
} from '~/features/base/constants/routes';
import {
    DELIVERABLE_TYPE_APP,
    DELIVERABLE_TYPE_BUNDLE,
    DELIVERABLE_TYPE_CM4G,
    DELIVERABLE_TYPE_DISTRO,
    DELIVERABLE_TYPE_FILE,
} from '~/features/deliverables/constants/deliverablesParameters';
import { ACCESSIBLE_CONTEXTS, CONTEXT_OWNERS } from '~/features/artifacts/constants/deliverablesParameters';
import { getPathWithoutDeliverabeIdAndVersion } from '~/features/deliverables/utils/utils';
import { deliverableContextInfoEntitiesSelector } from '~/features/artifacts/selectors/deliverableSelectors';
import { toShortSemanticVersion } from '~/features/base/utils/versionNumberConverter';
import find from 'lodash/fp/find';
import { permissionsSelector } from '~/features/user/selectors/permissionSelectors';
import {
    PERMISSION_SOFTWARE_INFO_DELETE,
    PERMISSION_SOFTWARE_INFO_UPDATE,
} from '~/features/base/constants/permissions';
import { deliverableManagementApi } from '~/api/deliverables/DeliverablesManagement.api';
import { deliverableErrorCodes } from '../constants/DeliverableValidateErrorCodes';
import {parseQuery} from "~/features/base/utils/query";

export const deliverableUserInfoEntitiesSelector = state => entityStoreSelector(state)[ENTITY_DELIVERABLES_USER_INFO];

export const deliverablesLoadingSelector = state => loadingStoreSelector(state)['deliverables'];
export const deliverableIdsLoadingSelector = state => loadingStoreSelector(state)['deliverableIds'];

export const deliverableEditorSelector = state => state.ui.deliverableEditorNeo;
export const deliverablePermissionSelector = state => state.ui.deliverablePermissions;
export const deliverable = state => state.ui.deliverablePermissions;

export const bundleReleaseStateWarningSelector = (state, model, isEdit) => {
    const isBundle = model?.deliverableType === DELIVERABLE_TYPE_BUNDLE.toUpperCase();
    if (model && isBundle) {
        const validateResponse = deliverableManagementApi.endpoints.validateDeliverable.select({
            deliverable: {
                deliverableId: model?.deliverableId || '',
                deliverableVersion: model?.deliverableVersion || '',
            },
            isEdit,
        })(state);
        const releaseStateWarnings = validateResponse?.data?.warnings?.filter(item => item?.errorCode === deliverableErrorCodes.bundleReleaseStateWarning);
        if (releaseStateWarnings && releaseStateWarnings.length) {
            return releaseStateWarnings;
        }
    }
    return false;
};

export const deliverableTypeSelector = createSelector(
    [pathnameSelector],
    (pathname) => {
        const pathNameWithoutDeliverableIdAndVersion = getPathWithoutDeliverabeIdAndVersion(pathname);
        switch (pathNameWithoutDeliverableIdAndVersion) {
            case `/${DELIVERABLES_PATH}/${DELIVERABLES_FILES_PATH}`:
                return DELIVERABLE_TYPE_FILE;
            case `/${DELIVERABLES_PATH}/${DELIVERABLES_APPS_PATH}`:
                return DELIVERABLE_TYPE_APP;
            case `/${DELIVERABLES_PATH}/${DELIVERABLES_DISTROS_PATH}`:
                return DELIVERABLE_TYPE_DISTRO;
            case `/${DELIVERABLES_PATH}/${DELIVERABLES_CM4G_PATH}`:
                return DELIVERABLE_TYPE_CM4G;
            case `/${DELIVERABLES_PATH}/${DELIVERABLES_BUNDLES_PATH}`:
                return DELIVERABLE_TYPE_BUNDLE;
            default:
                return DELIVERABLE_TYPE_DISTRO;
        }
    },
);
export const bundleTypeSelector = createSelector(
    [searchSelector],
    (search) => {
        const parsedQuery = parseQuery(search);
        return parsedQuery.bundleType;
    },
);
export const cm4gTypeSelector = createSelector(
    [searchSelector],
    (search) => {
        const parsedQuery = parseQuery(search);
        return parsedQuery.cm4GType;
    },
);

const liveAppsEntitiesSelector = state => entityStoreSelector(state)[ENTITY_LIVE_APPS];
const liveCM4GsEntitiesSelector = state => entityStoreSelector(state)[ENTITY_LIVE_CM4GS];
const liveBundlesEntitiesSelector = state => entityStoreSelector(state)[ENTITY_LIVE_BUNDLES];

export const liveAppsSelector = (state) => liveAppsEntitiesSelector(state);
export const liveCM4GsSelector = (state) => liveCM4GsEntitiesSelector(state);
export const liveBundlesSelector = (state) => liveBundlesEntitiesSelector(state);

export const canUpdateDeliverablesSelector = state =>
    permissionsSelector(state).includes(PERMISSION_SOFTWARE_INFO_UPDATE);

export const canDeleteDeliverablesSelector = state =>
    permissionsSelector(state).includes(PERMISSION_SOFTWARE_INFO_DELETE);

export const deliverablesPermissionsSelector = createSelector(
    [deliverablePermissionSelector],
    (permissions) => {
        return permissions ? permissions : [];
    },
);

export const deliverablesContextsSelector = createSelector(
    [deliverableUserInfoEntitiesSelector],
    (entities) => {
        return entities ? entities[ACCESSIBLE_CONTEXTS] : [];
    },
);

export const selectedContextOwnersSelector = createSelector(
    [deliverableContextInfoEntitiesSelector],
    (entities) => {
        return entities ? entities[CONTEXT_OWNERS] : [];
    },
);

export const deliverableEntitiesSelector = state => entityStoreSelector(state)[ENTITY_DELIVERABLE];
const deliverablePagesSelector = state => getOr({}, ENTITY_DELIVERABLE_SCOPE_DEVICES,
    indicesStoreSelector(state)[ENTITY_DELIVERABLE]);
export const deliverablePageNumberSelector = state => get('number', deliverablePagesSelector(state));

export const deliverableIdEntitiesSelector = state => entityStoreSelector(state)[ENTITY_DELIVERABLE_ID];
const deliverableIdsPagesSelector = state => getOr({}, ENTITY_DELIVERABLE_IDS_SCOPE_DEVICES,
    indicesStoreSelector(state)[ENTITY_DELIVERABLE_ID]);
export const deliverableIdPageNumberSelector = state => get('number', deliverableIdsPagesSelector(state));

export const deliverablePageItemsSelector = createSelector(
    [deliverablePagesSelector, deliverableEntitiesSelector, deliverablePageNumberSelector],
    (pages, entities, pageNumber) => {
        return entities;
    },
);

export const deliverableByVersionSelector = (state, ownProps) => {
    const deliverables = deliverablePageItemsSelector(state, ownProps);
    const selectedDeliverableDetails = find(function(deliverable) {
        return toShortSemanticVersion(deliverable.deliverableVersion) === ownProps?.selectedDeliverableVersion;
    }, deliverables);
    return selectedDeliverableDetails;
};

export const deliverableIdsPageItemsSelector = createSelector(
    [deliverableIdsPagesSelector, deliverableIdEntitiesSelector, deliverableIdPageNumberSelector],
    (pages, entities, pageNumber) => {
        // return map(id => entities[id], getOr([], pageNumber, pages));
        return entities;
    },
);

export const deliverableIdsTotalCountSelector = (state) => {
    const aggregations = aggregationStoreSelector(state);
    return !aggregations.deliverableId.totalCount ? 0 : aggregations.deliverableId.totalCount;
};

export const deliverableIdsItemSelector = (state) => {
    const indices = indicesStoreSelector(state);
    const len = !indices.deliverableId ? 0 : indices.deliverableId.length;
    return len;
};

export const distroCountsByReleaseStateSelector = state => distroAggregationsSelector(state).countsByReleaseState;
export const liveDistrosSelector = (state) =>
    liveDistrosEntitiesSelector(state);

const liveDistrosEntitiesSelector = state => entityStoreSelector(state)[ENTITY_LIVE_DISTROS];
const distroAggregationsSelector = state => aggregationStoreSelector(state)[ENTITY_DISTRO];

export const appsIdsSelector = state => state.ui.deliverablesNeo.app;
export const cm4gIdsSelector = state => state.ui.deliverablesNeo.cm4g;
export const fileIdsSelector = state => state.ui.deliverablesNeo.file;
export const bundlesIdsSelector = state => state.ui.deliverablesNeo.bundle;

export const groupsDependenciesSelector = createSelector(
    [appsIdsSelector, cm4gIdsSelector, fileIdsSelector, bundlesIdsSelector],
    (apps, cm4g, files, bundles) => {
        const appsWithType = apps?.map(deliverableId => ({
            id: deliverableId,
            type: DELIVERABLE_TYPE_APP.toUpperCase(),
        })) || [];
        const cmg4WithType = cm4g?.map(deliverableId => ({
            id: deliverableId,
            type: DELIVERABLE_TYPE_CM4G.toUpperCase(),
        })) || [];
        const fileWithType = files?.map(deliverableId => ({
            id: deliverableId,
            type: DELIVERABLE_TYPE_FILE.toUpperCase(),
        })) || [];
        const bundleWithType = bundles?.map(deliverableId => ({
            id: deliverableId,
            type: DELIVERABLE_TYPE_BUNDLE.toUpperCase(),
        })) || [];

        return [...appsWithType, ...cmg4WithType, ...fileWithType, ...bundleWithType];

    },
);

export const cm4gDependenciesSelector = createSelector(
    [cm4gIdsSelector, fileIdsSelector],
    (cm4g, files) => {

        const cmg4WithType = cm4g?.map(deliverableId => ({
            id: deliverableId,
            type: DELIVERABLE_TYPE_CM4G.toUpperCase(),
        })) || [];
        const fileWithType = files?.map(deliverableId => ({
            id: deliverableId,
            type: DELIVERABLE_TYPE_FILE.toUpperCase(),
        })) || [];
        return [...cmg4WithType, ...fileWithType];

    },
);

export const appsDependenciesSelector = createSelector(
    [appsIdsSelector, deliverableIdEntitiesSelector],
    (entities) => {

        return entities?.map(deliverableId => ({ id: deliverableId, type: DELIVERABLE_TYPE_APP.toUpperCase() })) || [];
    },
);

export const bundleDependenciesSelector = createSelector(
    [cm4gIdsSelector, fileIdsSelector],
    (cm4g, files) => {

        const cmg4WithType = cm4g?.map(deliverableId => ({
            id: deliverableId,
            type: DELIVERABLE_TYPE_CM4G.toUpperCase(),
        })) || [];
        const fileWithType = files?.map(deliverableId => ({
            id: deliverableId,
            type: DELIVERABLE_TYPE_FILE.toUpperCase(),
        })) || [];
        return [...cmg4WithType, ...fileWithType];
    },
);
