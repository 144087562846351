import React, { useMemo } from "react";
import RadioButton from "@rio-cloud/rio-uikit/RadioButton";
import { ALL, deliverableSubTypeOptions } from "~/features/base/constants/filterOptions";
import { useSelector, useDispatch } from 'react-redux';
import { pathnameSelector, searchSelector } from "~/features/base/selectors/locationSelectors";
import { followRoute } from "~/features/base/actions/ui/routeActions";
import {
    DELIVERABLE_TYPE_CM4G,
    FETCH_DELIVERABLES_AMOUNT_INCREMENT
} from "~/features/deliverables/constants/deliverablesParameters";
import { parseQuery } from "~/features/base/utils/query";

interface Cm4gTypeTabsProps {}

interface Option {
    id: string;
    label: string;
}

const cm4gTypes: Option[] = deliverableSubTypeOptions[DELIVERABLE_TYPE_CM4G];

const Cm4gTypeTabs: React.FC<Cm4gTypeTabsProps> = () => {
    const dispatch = useDispatch();
    const pathname = useSelector(pathnameSelector);
    const search = useSelector(searchSelector);
    const parsedQuery = useMemo(() => parseQuery(search), [search]);
    debugger

    const handleChange = (item: Option) => {
        dispatch(followRoute({
            route: pathname,
            query: {
                ...parsedQuery,
                cm4gType: item.id,
                page: 0,
                size: FETCH_DELIVERABLES_AMOUNT_INCREMENT,
            },
        }));
    };

    const isSelected = (item: Option) =>
        item.id === ALL && parsedQuery.cm4gType === undefined || item.id?.toLowerCase() === parsedQuery.cm4GType?.toLowerCase();

    const getSelectedClass = (item: Option) =>
        isSelected(item) ? 'border-color-highlight bg-highlight-decent' : 'hover-bg-highlight-decent';

    return (
        <div className='btn-toolbar display-flex margin-bottom-20'>
            {cm4gTypes.map((item) => (
                <RadioButton
                    key={item.id}
                    name='noTickRadioBoxes'
                    custom
                    style={{ borderRadius: '100px' }}
                    className={`padding-5 border rounded-extra-large ${getSelectedClass(item)}`}
                    onChange={() => handleChange(item)}
                    checked={isSelected(item)}
                >
                    <div className='text-center text-size-14 text-medium margin-left-5 margin-right-5'>
                        {item.label}
                    </div>
                </RadioButton>
            ))}
        </div>
    );
};

export default Cm4gTypeTabs;
